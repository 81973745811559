/* eslint-disable max-lines */
import i18next from 'i18next';

i18next.addResources('es', 'General', {
  remove: 'Remover',
  continue: 'Continuar',
  begin: 'Comenzar',
  termsAndConditions: 'Términos y condiciones',
  terms: 'Condiciones generales',
  applicationRequest: 'Solicitud de afiliación',
  applicationRequestF1: 'Solicitud de modificación',
  applicationRequestDDJJ: 'Solicitud de información',
  applicationRequestOTHERS: 'Solicitud de trámite',
  document: 'Documento',
  cancel: 'Cancelar'
});

i18next.addResources('es', 'Form', {
  send: 'Enviar solicitud',
  sendDataError:
    'Hubo un error con el envío. Por favor cerrá la ventana y dentro de unos minutos ingresa nuevamente en el link que has recibido por email. No te preocupes que tus datos están guardados. De persistir el error por favor comunícate con tu Asesor Comercial. (E-{{error}})'
});

i18next.addResources('es', 'InputValidations', {
  required: 'Requerido',
  length: 'Deben ser {{length}} caracteres',
  min: 'Ingrese mín. {{min}} caracteres',
  max: 'Max {{max}} caracteres',
  minValue: 'El valor mínimo es de {{min}}',
  maxValue: 'El valor máximo es de {{max}}',
  repeatedDocumentNumber: 'Los números de documento no pueden ser iguales',
  pastDate: 'Ingrese una fecha mayor a la actual',
  onlyAlphanumeric: 'Por favor, ingresá caracteres alfanuméricos',
  onlyAlphanumericWhithSymbols: 'Por favor, ingresá caracteres alfanuméricos, barras "/" o guiones "-"',
  onlyLetters: 'Por favor, ingresá caracteres alfabéticos únicamente',
  onlyNumbers: 'Por favor, ingresá caracteres numéricos enteros únicamente',
  lettersAndNumbers: 'Por favor, ingresá caracteres numéricos enteros o alfabéticos únicamente',
  email: 'El formato del e-mail no es válido',
  repeatedCuil: 'El cuil del titular no puede ser igual al del beneficiario',
  repeatedEmail: 'El e-mail ya fue cargado para otro integrante, por favor cargue uno diferente',
  betweenAYear: 'El valor debe estar en un rango de un año al día de hoy',
  betweenHundredYears: 'El valor debe estar en un rango de cien años al día de hoy',
  repeatedCuit: 'Los CUIT de las empresas no pueden ser iguales',
  repeatedCuilProspect: 'El documento ya fue utilizado para uno de los integrantes',
  invalidDate: 'Fecha inválida',
  invalidYear: 'Año invalido',
  invalidDueDate: 'La fecha no debe superar los 10 años a la fecha actual',
  underAge: 'Debe tener al menos 18 años',
  exceedAge:
    'La edad excede la máxima permitida. Continuá con el formulario y luego consultá con tu asesor comercial.',
  olderThanSixteen: 'Debes tener al menos 16 años',
  invalidDateMentruation:
    'La fecha no debe ser mayor a 6 meses de la fecha actual, en tal caso tildar no aplica.',
  maxExpirationYears: 'La fecha de vencimiento no puede ser mayor a 10 años',
  minMaxPhone: 'Ingrese un número entre {{min}} y {{max}} dígitos',
  repeatedPhone:
    'El número de celular ingresado ya fue cargado para otro integrante, por favor cargue uno diferente',
  dniAndCuilDoNotMatch: 'El CUIL y el DNI no coinciden'
});

i18next.addResources('es', 'SelectOptions', {
  yes: 'Sí',
  no: 'No',
  female: 'Femenino',
  male: 'Masculino',
  nonBinary: 'No Binario'
});

i18next.addResources('es', 'DocumentType', {
  dni: 'DNI',
  passport: 'Pasaporte',
  dniShort: 'DNI',
  passportShort: 'Pasaporte'
});

i18next.addResources('es', 'Profession', {
  1: 'AUTONOMO COMERCIAL - COMERCIANTE',
  2: 'AUTONOMO COMERCIAL - REMATADORES',
  3: 'AUTONOMO COMERCIAL - CORREDORES',
  4: 'AUTONOMO COMERCIAL - GANADERO',
  5: 'AUTONOMO COMERCIAL - AGRICULTOR',
  6: 'AUTONOMO COMERCIAL - CONSTRUCTOR',
  7: 'AUTONOMO PROFESIONAL - ABOGADO',
  8: 'AUTONOMO PROFESIONAL - INGENIERO',
  9: 'AUTONOMO PROFESIONAL - ARQUITECT',
  10: 'AUTONOMO PROFESIONAL - CONTADOR',
  11: 'AUTONOMO PROFESIONAL - MEDICO',
  12: 'AUTONOMO PROFESIONAL - AGRONOMO',
  13: 'AUTONOMO PROFESIONAL - VETERINAR',
  14: 'AUTONOMO PROFESIONAL - ODONTOLOG',
  15: 'AUTONOMO PROFESIONAL - FARMACEUT',
  16: 'AUTONOMOS JUBILADOS - RETIRADOS',
  17: 'AUTONOMO PROFESIONAL - BIOQUIM',
  18: 'AUTONOMOS PROFESIONAL - FONOUD',
  19: 'AUTONOMO PROFESIONAL - KINESIO',
  20: 'AUTONOMO PROFESIONAL - PSICOLO',
  21: 'AUTONOMO PROFESIONAL - RADIOLO',
  22: 'AUTONOMOS ACTIVIDADES VARIAS',
  23: 'AUTONOMOS AMAS DE CASA',
  24: 'APRENDICES ENTRE 14 Y 25 AÑOS',
  25: 'ESTUDIANTES',
  26: 'ADMINISTRATIVOS',
  27: 'ESCRIBANO',
  28: 'PROVEEDOR OSDE',
  29: 'PRESTADOR OBLIGATORIO OSDE',
  30: 'DESCTO. OSDE PROFESIONAL MEDICO',
  31: 'OTROS'
});

i18next.addResources('es', 'TaxCondition', {
  final: 'IVA Consumidor Final',
  exent: 'IVA Exento',
  responsable: 'IVA Responsable Inscripto'
});

i18next.addResources('es', 'RetirementInsurance', {
  ACO: 'Aconcagua',
  AGR: 'Federación Agraria',
  ANT: 'Anticipar',
  ASE: 'Aseguradora Solidarios',
  BIN: 'Binaria',
  BSA: 'Buenos Aires N y L',
  CON: 'Consolidar',
  CRI: 'San Cristobal',
  ENT: 'Inst. Entre Ríos',
  EST: 'Estrella',
  FED: 'Federación Patronal',
  FRA: 'Fraterna',
  GEN: 'Generar',
  GES: 'Génesis',
  HOL: 'Holando Argentina',
  ITT: 'ITT Harford',
  MAÑ: 'Mañana',
  MET: 'Metropolitan Life',
  NAC: 'Nación',
  OME: 'Omega',
  POL: 'Metropol',
  PRE: 'Previnter',
  PRO: 'Profuturo',
  PRV: 'Previsión',
  PRY: 'Proyección',
  SAN: 'Sancor',
  SEG: 'Segunda',
  SEN: 'Senium',
  SIE: 'Siembra',
  UAP: 'U.A.P.',
  ZUR: 'Zurich Iguazu'
});

i18next.addResources('es', 'Province', {
  1: 'Ciudad Autonoma Buenos Aires',
  2: 'Buenos Aires',
  3: 'Catamarca',
  4: 'Córdoba',
  5: 'Corrientes',
  6: 'Entre Ríos',
  7: 'Jujuy',
  8: 'Mendoza',
  9: 'La Rioja',
  10: 'Salta',
  11: 'San Juan',
  12: 'San Luis',
  13: 'Santa Fe',
  14: 'Santiago del Estero',
  16: 'Tucumán',
  17: 'Chaco',
  18: 'Chubut',
  19: 'Formosa',
  20: 'Misiones',
  21: 'Neuquén',
  22: 'La Pampa',
  23: 'Río Negro',
  24: 'Santa Cruz',
  25: 'Tierra de Fuego'
});

i18next.addResources('es', 'Countries', {
  AD: 'Andorra',
  AE: 'E.A.U.',
  AF: 'Afganistán',
  AG: 'Antigua/Barbuda',
  AI: 'Anguilla',
  AL: 'Albania',
  AM: 'Armenia',
  AN: 'Antillas hol.',
  AO: 'Angola',
  AQ: 'Antártida',
  AR: 'Argentina',
  AS: 'Samoa americana',
  AT: 'Austria',
  AU: 'Australia',
  AW: 'Aruba',
  AZ: 'Azerbaiyán',
  BA: 'Bosnia-Herz.',
  BB: 'Barbados',
  BD: 'Bangladesh',
  BE: 'Bélgica',
  BF: 'Burkina Faso',
  BG: 'Bulgaria',
  BH: 'Bahrein',
  BI: 'Burundi',
  BJ: 'Benin',
  BM: 'Bermudas',
  BN: 'Brunei Darussal',
  BO: 'Bolivia',
  BR: 'Brasil',
  BS: 'Bahamas',
  BT: 'Bután',
  BV: 'Islas Bouvet',
  BW: 'Botsuana',
  BY: 'Bielorrusia',
  BZ: 'Belice',
  CA: 'Canadá',
  CC: 'Islas Coco',
  CD: 'República Congo',
  CF: 'Rep.Centroafr.',
  CG: 'Congo',
  CH: 'Suiza',
  CI: 'Costa de Marfil',
  CK: 'Islas Cook',
  CL: 'Chile',
  CM: 'Camerún',
  CN: 'China',
  CO: 'Colombia',
  CR: 'Costa Rica',
  CU: 'Cuba',
  CV: 'Cabo Verde',
  CX: 'Islas Navidad',
  CY: 'Chipre',
  CZ: 'República Checa',
  DE: 'Alemania',
  DJ: 'Yibuti',
  DK: 'Dinamarca',
  DM: 'Dominica',
  DO: 'Rep.Dominicana',
  DZ: 'Argelia',
  EC: 'Ecuador',
  EE: 'Estonia',
  EG: 'Egipto',
  EH: 'Sáhara occid.',
  ER: 'Eritrea',
  ES: 'España',
  ET: 'Etiopía',
  FI: 'Finlandia',
  FJ: 'Fiji',
  FM: 'Micronesia',
  FO: 'Islas Feroé',
  FR: 'Francia',
  GA: 'Gabón',
  GB: 'Reino Unido',
  GD: 'Granada',
  GE: 'Georgia',
  GF: 'Guayana Franc.',
  GH: 'Ghana',
  GI: 'Gibraltar',
  GL: 'Groenlandia',
  GM: 'Gambia',
  GN: 'Guinea',
  GP: 'Guadalupe',
  GQ: 'Guinea Ecuator.',
  GR: 'Grecia',
  GS: 'Isl.S.Sandwich',
  GT: 'Guatemala',
  GU: 'Guam',
  GW: 'Guinea-Bissau',
  GY: 'Guyana',
  HK: 'Hong Kong',
  HM: 'de Heard/Is.Mc.',
  HN: 'Honduras',
  HR: 'Croacia',
  HT: 'Haití',
  HU: 'Hungría',
  ID: 'Indonesia',
  IE: 'Irlanda',
  IL: 'Israel',
  IN: 'India',
  IO: 'Terr.br.Oc.Ind.',
  IQ: 'Irak',
  IR: 'Irán',
  IS: 'Islandia',
  IT: 'Italia',
  JM: 'Jamaica',
  JO: 'Jordania',
  JP: 'Japón',
  KE: 'Kenia',
  KG: 'Kirguizistán',
  KH: 'Camboya',
  KI: 'Kiribati',
  KM: 'Comoras',
  KN: 'St.Chr.,Nevis',
  KP: 'Corea del Norte',
  KR: 'Corea del Sur',
  KW: 'Kuwait',
  KY: 'Islas Caimán',
  KZ: 'Kazajstán',
  LA: 'Laos',
  LB: 'Líbano',
  LC: 'Santa Lucía',
  LI: 'Liechtenstein',
  LK: 'Sri Lanka',
  LR: 'Liberia',
  LS: 'Lesotho',
  LT: 'Lituania',
  LU: 'Luxemburgo',
  LV: 'Letonia',
  LY: 'Libia',
  MA: 'Marruecos',
  MC: 'Mónaco',
  MD: 'Moldavia',
  MG: 'Madagascar',
  MH: 'Islas Marshall',
  MK: 'Macedonia',
  ML: 'Mali',
  MM: 'Myanmar',
  MN: 'Mongolia',
  MO: 'Macau',
  MP: 'Isla N.Mariana',
  MQ: 'Martinicas',
  MR: 'Mauritania',
  MS: 'Montserrat',
  MT: 'Malta',
  MU: 'Mauricio (Isl.)',
  MV: 'Islas Maldivas',
  MW: 'Malaui',
  MX: 'México',
  MY: 'Malasia',
  MZ: 'Mozambique',
  NA: 'Namibia',
  NC: 'Nueva Caledonia',
  NE: 'Niger',
  NF: 'Islas Norfolk',
  NG: 'Nigeria',
  NI: 'Nicaragua',
  NL: 'Países Bajos',
  NO: 'Noruega',
  NP: 'Nepal',
  NR: 'Nauru',
  NU: 'Islas Niue',
  NZ: 'Nueva Zelanda',
  OM: 'Omán',
  PA: 'Panamá',
  PE: 'Perú',
  PF: 'Polinesia fran.',
  PG: 'PapuaNvaGuinea',
  PH: 'Filipinas',
  PK: 'Pakistán',
  PL: 'Polonia',
  PM: 'StPier.,Miquel.',
  PN: 'Islas Pitcairn',
  PR: 'Puerto Rico',
  PT: 'Portugal',
  PW: 'Palau',
  PY: 'Paraguay',
  QA: 'Qatar',
  RE: 'Reunión',
  RO: 'Rumanía',
  RU: 'Federación Rusa',
  RW: 'Ruanda',
  SA: 'Arabia Saudí',
  SB: 'Islas Salomón',
  SC: 'Seychelles',
  SD: 'Sudán',
  SE: 'Suecia',
  SG: 'Singapur',
  SH: 'Santa Helena',
  SI: 'Eslovenia',
  SJ: 'Svalbard',
  SK: 'Eslovaquia',
  SL: 'Sierra Leona',
  SM: 'San Marino',
  SN: 'Senegal',
  SO: 'Somalia',
  SR: 'Surinám',
  ST: 'S.Tomé,Príncipe',
  SV: 'El Salvador',
  SY: 'Siria',
  SZ: 'Swazilandia',
  TC: 'de Turks,-Caic.',
  TD: 'Chad',
  TF: 'French S.Territ',
  TG: 'Togo',
  TH: 'Tailandia',
  TJ: 'Tadjikistán',
  TK: 'Islas Tokelau',
  TM: 'Turkmenistán',
  TN: 'Túnez',
  TO: 'Tonga',
  TP: 'Timor oriental',
  TR: 'Turquía',
  TT: 'Trinidad,Tobago',
  TV: 'Tuvalú',
  TW: 'Taiwan',
  TZ: 'Tanzania',
  UA: 'Ucrania',
  UG: 'Uganda',
  UM: 'de IslMinorOutl',
  US: 'EE.UU.',
  UY: 'Uruguay',
  UZ: 'Uzbekistán',
  VA: 'Ciudad Vaticano',
  VC: 'San Vicente',
  VE: 'Venezuela',
  VG: 'Isl.Vírgenes GB',
  VI: 'Is.Vírgenes USA',
  VN: 'Vietnam',
  VU: 'Vanuatu',
  WF: 'Wallis,Futuna',
  WS: 'Samoa Occident.',
  YE: 'Yemen',
  YT: 'Mayotte',
  YU: 'Yugoslavia',
  ZA: 'Sudáfrica',
  ZM: 'Zambia',
  ZW: 'Zimbabwe'
});
